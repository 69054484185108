import { makeStyles, createStyles } from "@material-ui/core/styles";
import colors from "../../common/styles/colors";

const useStyles = makeStyles((theme) =>
  createStyles({
    headerContainer: {
      backgroundColor: colors.primaryColor,
      position: "initial",
      width: "100%",
      paddingRight: "0 !important",
      minHeight: "64px",
      boxShadow: "none",
    },
    wrapContainer: {
      // flexWrap: "nowrap",
      [theme.breakpoints.up("lg")]: {
        paddingLeft: "56px",
      },
    },
    logo: {
      alignSelf: "center",
      paddingRight: "38px",
      "& img": {
        height: "auto",
        width: "70px",
        imageRendering: "-webkit-optimize-contrast"
      },
      [theme.breakpoints.down("xs")]: {
        paddingTop: "15px",
      },
    },
    menu: {
      display: "flex",
      width: "51%",
      [theme.breakpoints.down("sm")]: {
        width: "auto",
      },
      [theme.breakpoints.down("xs")]: {
        flexWrap: "wrap",
      },
    },
    align: {
      [theme.breakpoints.up("sm")]: {
        justifyContent: "space-around",
      },
    },
    alignStart: {
      justifyContent: "start",
    },
    items: {
      "& a": {
        color: colors.secondaryColor,
        textDecoration: "none",
        lineHeight: "58px",
        borderBottom: `6px solid transparent`,
        minWidth: "90px",
        height: "100%",
        textAlign: "center",
        [theme.breakpoints.up("lg")]: {
          minWidth: "132px",
          textAlign: "center",
        },
      },
      "& a.underline": {
        borderBottom: `6px solid ${colors.veryLightGreyColor}`,
      },
      fontSize: "16px",
      fontFamily: "Roboto",
      lineHeight: "18px",
      fontWeight: 500,
      letterSpacing: "-0.04em",
      overflow: "visible",
      paddingTop: 0,
      paddingBottom: 0,
      "&.MuiListItem-root.Mui-selected": {
        backgroundColor: "transparent",
      },
      "& .MuiTouchRipple-root": {
        display: "none",
      },
    },
    myAccount: {
      alignSelf: "center",
      marginLeft: "auto",
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("xs")]: {
        marginLeft: 0,
      },
    },
    languages: {
      marginLeft: theme.spacing(3),
      marginTop: theme.spacing(2),
      [theme.breakpoints.down("xs")]: {
        marginBottom: theme.spacing(2),
        marginLeft: 0,
      },
    },
    underline: {},
    badge: {
      "& .MuiBadge-badge": {
        height: "16px",
        minWidth: "16px",
        fontSize: "10px",
        lineHeight: "11px",
        fontFamily: "Roboto, sans-serif",
        color: colors.primaryColor,
        backgroundColor: colors.veryLightGreyColor,
      },
      "& .MuiBadge-anchorOriginTopRightRectangle": {
        top: "20px",
        right: "-18px",
      },
    },
    hideInstaller: {
      marginLeft: "80px",
      marginTop: "6px",
      marginBottom: "6px",
      paddingLeft:"0px",
      fontSize: "16px",
      lineHeight: "1",
      textTransform: "none",
      textDecoration: "underline",
      paddingRight: "18px"
    },
    disableInstaller: {
      marginTop: "6px",
      marginBottom: "6px",
      paddingLeft:"0px",
      fontSize: "16px",
      lineHeight: "1",
      textTransform: "none",
      float: "right",
      marginRight: "16px",
      textDecoration: "underline"
    }
  })
);

export default useStyles;
