import { makeStyles, createStyles } from "@material-ui/core/styles";
import colors from "../../../common/styles/colors";

const useStyles = makeStyles((theme) =>
  createStyles({
    modalDeleteCard: {
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "508px",
      height: "402px",
      paddingTop: theme.spacing(13),
      position: "absolute",
      backgroundColor: colors.secondaryColor,
      alignItems: "center",
    },
    actionsBar: {
      justifyContent: "space-between",
      flexWrap: "nowrap",
    },
    actionsWrapper: {
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        width: "fit-content",
        justifyContent: "flex-start",
      },
      justifyContent: "space-between",
      "& button": {
        marginRight: "40px",
      },
    },
    modalExportCard: {
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "508px",
      height: "488px",
      paddingTop: theme.spacing(13),
      position: "absolute",
      backgroundColor: colors.secondaryColor,
      alignItems: "center",
    },
  })
);

export default useStyles;
