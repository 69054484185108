import { makeStyles, createStyles } from "@material-ui/core/styles";
import colors from "../../common/styles/colors";
import { commonParagraph14px } from "../../common/styles/typography";

const useStyles = makeStyles((theme) =>
  createStyles({
    projectsWrapper: {
      margin: "0 auto",
      maxWidth: "1040px",
      paddingTop: theme.spacing(5),
      [theme.breakpoints.down("md")]: {
        width: "100%",
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    },
    projectsContainer: {
      paddingTop: theme.spacing(4),
    },
    table: {
      margin: "40px 0 95px 0",
      "& th": {
        borderBottom: "none",
      },
      "& td": {
        borderBottom: "none",
      },
      "& tr": {
        borderBottom: `1px solid ${colors.veryLightGreyColor}`,
      },
      "& tr:last-child":{
        borderBottom: "none",
      }
    },
    projectsTableHead: {
      backgroundColor: colors.secondaryColor,
    },
    columnName: {
      color: colors.darkGreyColor,
      fontWeight: 800,
      ...commonParagraph14px,
    },
    projectName: {
      color: colors.blackColor,
      fontWeight: 800,
      ...commonParagraph14px,
      wordBreak: "break-word",
    },
    content: {
      color: colors.blackColor,
      ...commonParagraph14px,
      wordBreak: "break-word",
    },
    check: {
      paddingLeft: "23px",
      width: "20px",
      height: "20px",
      "& span": {
        padding: 0,
      },
    },
    rowContent: {
      cursor: "default",
    },
  })
);

export default useStyles;
