export enum AppRoute {
  Home = "home",
  About = "about",
  ProjectsMy = "projectsMy",
  Project = "project",
  Detector = "detector",
  Templates = "templates",
  Register = "register",
  RegisterConfirmation = "registerConfirmation",
  ResetEmailConfirmation = "resetEmailConfirmation",
  AccountActivated = "accountActivated",
  ActivationLinkExpired = "activationLinkExpired",
  ActivationFailed = "activationFailed",
  ChangeEmailConfirmation = "changeEmailConfirmation",
  MyAccount = "myAccount",
  ForgotPassword = "forgotPassword",
  ResetPassword = "resetPassword",
  ChangeEmailStatus = "changeEmail",
  InstallerView = "installerView",
  InstallerViewLoggedIn = "installerViewLoggedIn",
  Page404 = "404"
}

export const AppRouteTitles = new Map([
  [AppRoute.Home, "home"],
  [AppRoute.About, "about"],
  [AppRoute.ProjectsMy, "projectsMy"],
  [AppRoute.Project, "project"],
  [AppRoute.Detector, "detector"],
  [AppRoute.Templates, "templates"],
  [AppRoute.Register, "register"],
  [AppRoute.RegisterConfirmation, "registerConfirmation"],
  [AppRoute.ResetEmailConfirmation, "resetEmailConfirmation"],
  [AppRoute.AccountActivated, "accountActivated"],
  [AppRoute.ActivationLinkExpired, "activationLinkExpired"],
  [AppRoute.ChangeEmailConfirmation, "changeEmailConfirmation"],
  [AppRoute.MyAccount, "myAccount"],
  [AppRoute.ForgotPassword, "forgotPassword"],
  [AppRoute.ResetPassword, "resetPassword"],
  [AppRoute.ChangeEmailStatus, "changeEmail"],
  [AppRoute.InstallerView, "installerView"],
  [AppRoute.InstallerViewLoggedIn, "installerViewLoggedIn"],
  [AppRoute.Page404, "404"]
]);
