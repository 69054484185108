import { makeStyles, createStyles } from "@material-ui/core/styles";
import colors from "../../../../common/styles/colors";
import {
  commonParagraph,
  commonParagraph14px,
} from "../../../../common/styles/typography";

const useStyles = makeStyles((theme) =>
  createStyles({
    receivedTemplatesContainer: {
      paddingTop: theme.spacing(4),
    },
    actionsBar: {
      justifyContent: "space-between",
      marginBottom: "40px",
      flexWrap: "nowrap",
      "& button": {
        [theme.breakpoints.down("md")]: {
          marginRight: "40px",
        },
      },
    },
    actionItems: {
      display: "flex",
      justifyContent: "flex-start",
      "& button": {
        marginRight: "40px",
      },
    },
    table: {
      tableLayout: "fixed",
      "& th": {
        borderBottom: "none",
      },
      "& td": {
        borderBottom: "none",
      },
      "& tr": {
        borderBottom: `1px solid ${colors.veryLightGreyColor}`,
      },
      "& tr:last-child":{
        borderBottom: "none",
      }
    },
    headingRow: {
      backgroundColor: colors.secondaryColor,
    },
    columnName: {
      color: colors.darkGreyColor,
      fontWeight: 800,
      ...commonParagraph14px,
    },
    check: {
      paddingRight: "7px",
      width: "20px",
      height: "20px",
    },
    templateName: {
      ...commonParagraph14px,
      color: colors.blackColor,
      fontWeight: 700,
      cursor: "default",
      wordBreak: "break-word",
    },
    content: {
      ...commonParagraph14px,
      color: colors.blackColor,
      wordBreak: "break-word",
    },
    detailsModalContainer: {
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "536px",
      flexWrap: "nowrap",
      height: "580px",
      overflowY: "scroll",
      position: "absolute",
      backgroundColor: colors.secondaryColor,
      alignItems: "center",
      padding: "35px 40px 60px",
    },
    modalDeleteCard: {
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "508px",
      padding: "88px 32px 70px",
      position: "absolute",
      backgroundColor: colors.secondaryColor,
      alignItems: "center",
    },
    modalExportCard: {
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "508px",
      height: "488px",
      paddingTop: theme.spacing(13),
      position: "absolute",
      backgroundColor: colors.secondaryColor,
      alignItems: "center",
    },
    modalSendCard: {
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "508px",
      height: "580px",
      overflowY: "scroll",
      flexWrap: "nowrap",
      padding: "88px 38px 20px",
      position: "absolute",
      backgroundColor: colors.secondaryColor,
      alignItems: "center",
    },
    modalStatusCard: {
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "508px",
      maxHeight: "488px",
      overflowY: "scroll",
      padding: "88px 32px 50px",
      position: "absolute",
      backgroundColor: colors.secondaryColor,
      alignItems: "center",
      flexWrap: "nowrap",
    },
    modalParagraph: {
      marginBottom: theme.spacing(6),
      color: colors.darkerGreyColor,
      textAlign: "center",
      ...commonParagraph,
    },
  })
);

export default useStyles;
