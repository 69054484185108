import { makeStyles, createStyles } from "@material-ui/core/styles";
import colors from "../../../common/styles/colors";
import { commonParagraph, mainHeader } from "../../../common/styles/typography";

const useStyles = makeStyles((theme) =>
  createStyles({
    resetPasswordWrapper: {
      //paddingTop: theme.spacing(10),
      //paddingBottom: theme.spacing(11),
      //width: "85%",
      //margin: "0 auto",
      marginBottom: "-6px",
      flexWrap: "nowrap",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        paddingTop: theme.spacing(3),
      },
    },
    title: {
      paddingBottom: theme.spacing(5.6),
      color: colors.blackColor,
      ...commonParagraph,
    },
    formTitle: {
      ...mainHeader,
      paddingBottom: theme.spacing(10),
      [theme.breakpoints.down("sm")]: {
        paddingBottom: theme.spacing(3),
      },
    },
    itemContainer: {
      width: "45%",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        textAlign: "center",
      },
    },
    contentWrapper: {
      width: "50%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        padding: "20px 15px",
      },
    },
    content: {
      width: "55%",
      color: colors.primaryColor,
      ...commonParagraph,
      textAlign: "center",
      paddingBottom: theme.spacing(5),
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    textInput: {
      width: "352px",
      borderRadius: "24px",
      backgroundColor: colors.veryLightGreyColor,
      color: colors.primaryColor,
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          border: "0",
        },
      },
      padding: "10px 24px",
      fontSize: "16px",
      lineHeight: "28px",
      fontFamily: "Roboto, sans-serif",
      "& .MuiOutlinedInput-input": {
        padding: "5px 0",
      },
      "& .MuiInputBase-root": {
        paddingRight: "0",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    required: {
      color: "red",
    },
    inputLabel: {
      color: colors.darkerGreyColor,
      ...commonParagraph,
      alignSelf: "start",
      paddingTop: "10px",
      "& .MuiInputLabel-asterisk": {
        color: "red",
      },
    },
    error: {
      color: colors.errorRedColor,
      fontSize: "12px",
      lineHeight: "16px",
      marginTop: "8px",
    },
    verticalInputSpacing: {
      paddingLeft: "8px",
      borderLeft: `4px solid transparent`,
      marginLeft: `-12px`,
      minHeight: "88px",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    errorBorder: {
      borderLeft: `4px solid ${colors.errorRedColor}`,
    },
    button: {
      width: "352px",
      marginTop: theme.spacing(4),
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    image: {
      //paddingLeft: "22px",
      paddingRight: "22px",
      //width: "100%",
    },
  })
);

export default useStyles;
