export const contactDetails: { [id: string]: { [id: string]: string } } = {
    'at-DE': {
        "item3_1": "Niko Österreich GmbH",
        "item3_2": "Am Belvedere 4\n1100 Wien\nverkauf-at@niko.eu",
        "item3_3": "T +43 1 7965514",
        "item3_4": "F +43 1 7965520"
    },
    'be-FR': {
        "item3_1": "Industriepark West 40",
        "item3_2": "9100 Sint-Niklaas",
        "item3_3": "T +32 3 778 90 00",
        "item3_4": ""
    },
    'be-NL': {
        "item3_1": "Industriepark West 40",
        "item3_2": "9100 Sint-Niklaas",
        "item3_3": "T +32 3 778 90 00",
        "item3_4": ""
    },
    'da-DK': {
        "item3_1": "Stenager 5",
        "item3_2": "6400 Sønderborg",
        "item3_3": "T +45 74 42 47 26",
        "item3_4": ""
    },
    'de-DE': {
        "item3_1": "Speicherstraße 59",
        "item3_2": "DE-60327 Frankfurt am Main",
        "item3_3": "T +49 (0) 7623 966 97-0",
        "item3_4": "F +49 (0) 7623 96697-7"
    },
    'en': {
        "item3_1": "Industriepark West 40",
        "item3_2": "9100 Sint-Niklaas",
        "item3_3": "T +32 3 778 90 00",
        "item3_4": ""
    },
    'fr-BE': {
        "item3_1": "Le Heron Building\n28 avenue René Cassin",
        "item3_2": "69009 Lyon",
        "item3_3": "T +33 82 020 66 25",
        "item3_4": "F +33820206629"
    },
    'it-CH': {
        "item3_1": "Leutschenbachstrasse 95",
        "item3_2": "8050 Zürich",
        "item3_3": "T +41 44 878 22 22",
        "item3_4": ""
    },
    'nb-NO': {
        "item3_1": "Stenager 5",
        "item3_2": "6400 Sønderborg",
        "item3_3": "T +45 74 42 47 26",
        "item3_4": "",
    },
    'nl-BE': {
        "item3_1": "Schorsweg 4",
        "item3_2": "8171 ME VAASSEN",
        "item3_3": "T: +31880159600",
        "item3_4": ""
    },
    'pl-PL': {
        "item3_1": "ul. Miodowa 14",
        "item3_2": "00-246 Warszawa",
        "item3_3": "T +48 50 820 03 06",
        "item3_4": ""
    },
    'sk-SK': {
        "item3_1": "Údernícka 9",
        "item3_2": "851 01 Bratislava",
        "item3_3": "T +421 2 63 82 51 55",
        "item3_4": ""
    },
    'sv-SE': {
        "item3_1": "Västberga Allé 32",
        "item3_2": "126 30 Hägersten",
        "item3_3": "T +46 84 102 00 15",
        "item3_4": ""
    },
    'sz-DE': {
        "item3_1": "Niko Schweiz AG",
        "item3_2": "Leutschenbachstr. 95\nCH - 8050 Zürich\nverkauf-ch@niko.eu",
        "item3_3": "T +41 44 878 22 22",
        "item3_4": "F +41 44 878 22 33"
    },
    'sz-FR': {
        "item3_1": "Niko Schweiz AG",
        "item3_2": "Leutschenbachstr. 95\nCH - 8050 Zürich\nverkauf-ch@niko.eu",
        "item3_3": "T +41 44 878 22 22",
        "item3_4": "F +41 44 878 22 33"
    },
    'sz-IT': {
        "item3_1": "Niko Schweiz AG",
        "item3_2": "Leutschenbachstr. 95\nCH - 8050 Zürich\nverkauf-ch@niko.eu",
        "item3_3": "T +41 44 878 22 22",
        "item3_4": "F +41 44 878 22 33"
    }
};
