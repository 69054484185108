import { IDetectorModel } from "../../models/DetectorModel";
import { IBleSensorInformation } from "../../models/BleSensorInformation";
import { IUserConfigModel } from "../../models/UserConfigModel";
import { IZone } from "../../models/Zone";
import { ISensorProfile } from "../../models/SensorProfile";
import { IZones } from "../../models/Zones";

export interface IDetectorResponseModel {
  id: string;
  favoriteName: string;
  sensorInformation: IBleSensorInformation;
  detectorType: string;
  macAddress: string;
  projectName: string;
  pinCode: string;
  createdAt: string;
  updatedAt: string;
  userConfigModel: string;
  sensorProfile: string;
  zones: string;
  isFavorite: boolean;
  settingType: string;
  hvacShortAddressExternalRelay: number;
  cutOffShortAddressExternalRelay: number;
  wiredPushButtonModel: string;
  blePushButtonModel: string;
  daliPushButtonModel: string;
  getSetTunableWhiteListModelReply: string;
  getSetTunableWhitePresetModelReply: string;
  daliDeviceDataScenesModelReplies: any[];
  getSetWirelessLinkFunctionModelReply: string;
}

const getZones = (userConfigModel: IUserConfigModel): IZone[] => {
  let zones: IZone[] = [];
  for (const property in userConfigModel) {
    if (property.indexOf("Zone") !== -1) {
      const zone = userConfigModel[property];
      if (!!zone) {
        zone.zoneNumber = property;
        zones.push(zone);
      }
    }
  }
  return zones;
};

export const mapFromDetectorResponseToIDetectorModel = (
  data: IDetectorResponseModel
): IDetectorModel => ({
  sensorSettingId: data.id,
  favoriteName: data.favoriteName,
  detectorType: data.sensorInformation.detectorType,
  macAddress: data.macAddress,
  projectName: data.projectName,
  pinCode: data.pinCode,
  lastUpload: data.updatedAt,
  sensorInformation: data.sensorInformation,
  userConfigModel: data.userConfigModel,
  sensorProfile: data.sensorProfile,
  zones: data.zones,
  isFavorite: data.isFavorite,
  bleSensorInformationId: data.sensorInformation.id,
  zonesToSend: data.zones,
  settingType: data.settingType,
  hvacShortAddressExternalRelay: data.hvacShortAddressExternalRelay,
  cutOffShortAddressExternalRelay: data.cutOffShortAddressExternalRelay,
  wiredPushButtonModel: data.wiredPushButtonModel,
  blePushButtonModel: data.blePushButtonModel,
  daliPushButtonModel: data.daliPushButtonModel,
  getSetTunableWhiteListModelReply: data.getSetTunableWhiteListModelReply,
  getSetTunableWhitePresetModelReply: data.getSetTunableWhitePresetModelReply,
  daliDeviceDataScenesModelReplies: data.daliDeviceDataScenesModelReplies,
  getSetWirelessLinkFunctionModelReply: data.getSetWirelessLinkFunctionModelReply
});
