import axios from 'axios';
import { CIAM_CALLBACK_ROUTE_URL } from '../common/constants/routeSuffixes';

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

axios.interceptors.request.use(request => {
    const existingToken = localStorage.getItem("tokens");
    if (existingToken) {
        if (existingToken.length < 500) {
            request.headers['Authorization'] = `Basic ${existingToken}`;
        }
        else {
            request.headers['Authorization'] = `Bearer ${existingToken}`;
        }
    }
    request.headers['Cache-Control'] = 'no-cache';
    request.headers['Pragma'] = 'no-cache';
    request.headers['Expires'] = '0';

    return request;
}, error => {
  return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use(response => {
    localStorage.removeItem('logoutInitiated');
    return response;
  }, error => {
    if (error.response.status === 401) {
        const logoutInitiated = localStorage.getItem('logoutInitiated');
        const loggedUserId = localStorage.getItem('currentUserId');

        if (error.response.config.headers.Authorization.startsWith('Bearer') && loggedUserId) {
            if (logoutInitiated === '2') {
                window.location.replace(window.location.origin);
            }
            else if (logoutInitiated === '1') {
                localStorage.setItem('logoutInitiated', '2');
            }
            else {
                localStorage.setItem('logoutInitiated', '1');
            }

            localStorage.setItem('toRefresh', 'true');

            window.location.replace(process.env.REACT_APP_CIAM_PROVIDER_URL+'/login?redirect_uri='+process.env.REACT_APP_BASE_URL+CIAM_CALLBACK_ROUTE_URL);
        }
    }
    return Promise.reject(error);
  });

class HTTPService {
    sendMessage(method: string, url: string, body = {}, options: any = { headers: {} }) {
        const request = method.toLowerCase();
        const error = 'The first sendMessage() parameter must have one of the following values: get, post, put or delete!';
        const extraOptions = options;

        switch (request) {
            case 'get':
                return this.get(url, extraOptions);
            case 'post':
                return this.post(url, body, extraOptions);
            case 'put':
                return this.put(url, body, extraOptions);
            case 'delete':
                return this.delete(url, extraOptions);
            default:
                throw new Error(error);
        }
    }

        private get = (url:string, options: any) => axios.get(url, options)

        private post = (url: string, body: object, options: any) => axios.post(url, body, options)

        private put = (url: string, body: object, options: any) => axios.put(url, body, options)

        private delete = (url: string, options: any) => axios.delete(url, options)
}

const httpService = new HTTPService();
export default httpService;
