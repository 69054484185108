import { makeStyles, createStyles } from "@material-ui/core/styles";
import colors from "../../../../common/styles/colors";
import {
  commonParagraph,
  primaryHeader,
} from "../../../../common/styles/typography";

const useStyles = makeStyles((theme) =>
  createStyles({
    headerTemplate: {
      marginTop: theme.spacing(5),
      paddingLeft: theme.spacing(15),
    },
    heading: {
      color: colors.primaryColor,
      marginBottom: theme.spacing(4),
      ...primaryHeader,
    },
    content: {
      color: colors.darkerGreyColor,
      ...commonParagraph,
    },
  })
);

export default useStyles;
