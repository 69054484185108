import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import { useAuth } from "../../context/context";
import {
  Grid,
  AppBar,
  Tabs,
  Tab,
  Box,
  Typography,
  Badge,
} from "@material-ui/core";
import FilterTemplates, {
  IFilterSet,
} from "./components/Filter/FilterTemplates";
import MyTemplates from "./components/MyTemplates/MyTemplates";
import ReceivedTemplates from "./components/ReceivedTemplates/ReceivedTemplates";
import TemplateHeader from "./components/HeaderTemplate/TemplateHeader";
import useStyles from "./Templates.style";
import useTypographyStyles from "../../common/styles/typography";
import { getAllDetectorsByType, getDetectorTypeFormattedForFilterMenu } from "../../data-services/Templates/TemplateMapper";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function tabProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Template: React.FC = () => {
  const classes = useStyles();
  const typoClasses = useTypographyStyles({ tabTitles: "MuiTab-wrapper" });
  const [value, setValue] = useState(0);
  const authStore = useAuth();
  const [myTemplatesFilters, setMyTemplatesFilters] = useState<IFilterSet>({});
  const [ receivedTemplatesFilters, setReceivedTemplatesFilters] = useState<IFilterSet>({});
  const [selectedReceivedTemplates, setSelectedReceivedTemplates] = useState<string[]>([]);
  const [selectedMyTemplates, setSelectedMyTemplates] = useState<string[]>([]);
  const { t } = useTranslation(["translation"]);

  const [currentTab, setCurrentTab] = useState(0);

  if (localStorage.getItem('templatesTabSelected') == 'receivedTemplates') {
      if (localStorage.getItem('previousPathEnding') == 'templates') {
        if (value == 0) {
          setValue(1);
          localStorage.setItem('templatesTabSelected', 'receivedTemplates');
        }
      }
      else {
        localStorage.setItem('templatesTabSelected', 'myTemplates');
      }
  }
  else {
    if (value == 1) {
      setValue(0);
      localStorage.setItem('templatesTabSelected', 'myTemplates');
    }
  }

  const handleTabs = (event: React.ChangeEvent<{}>, newValue: number) => {
    if (newValue == 0) {
        localStorage.setItem('templatesTabSelected', 'myTemplates');
    }
    else if (newValue == 1) {
        localStorage.setItem('templatesTabSelected', 'receivedTemplates');
    }

    setValue(newValue);
    setCurrentTab(newValue);
  };

  const getTabsData = (data: any) => {
    const filters: IFilterSet = {};
    data.map((template: any) => {
      const familyName = template.sensorInformation.detectorFamily;
      if (familyName.includes("230V")) {
        const vTemplates = filters["230V"] || [];
        const filter = vTemplates.find(
          (filter) => filter.detectorType === getDetectorTypeFormattedForFilterMenu(template, t)
        );
        if (filter === undefined) {
          filters["230V"] = [
            ...vTemplates,
            {
              name: template.sensorInformation.detectorShortDescription,
              detectorType: getDetectorTypeFormattedForFilterMenu(template, t),
              count: 1,
            },
          ];
        } else {
          filter.count++;
          const newFilters = filters["230V"].filter(
            (filter) => filter.detectorType !== getDetectorTypeFormattedForFilterMenu(template, t)
          );
          filters["230V"] = [...newFilters, filter];
        }
      }
      if (familyName.includes("dali")) {
        const daliTemplates = filters["dali"] || [];
        const daliFilter = daliTemplates.find(
          (filter) => filter.detectorType === getDetectorTypeFormattedForFilterMenu(template, t)
        );
        if (daliFilter === undefined) {
          filters["dali"] = [
            ...daliTemplates,
            {
              name: template.sensorInformation.detectorShortDescription,
              detectorType: getDetectorTypeFormattedForFilterMenu(template, t),
              count: 1,
            },
          ];
        } else {
          daliFilter.count++;
          const newDaliFilter = filters["dali"].filter(
            (filter) => filter.detectorType !== getDetectorTypeFormattedForFilterMenu(template, t)
          );
          filters["dali"] = [...newDaliFilter, daliFilter];
        }
      }
    });

    fillWithTheRestOfTheFilters(filters, "230V");
    fillWithTheRestOfTheFilters(filters, "dali");

    sortFilters(filters, "230V");
    sortFilters(filters, "dali");

    switch (value) {
      case 0:
        setMyTemplatesFilters(filters);
        break;
      case 1:
        setReceivedTemplatesFilters(filters);
        break;
    }
  };

  const sortFilters = (filters: any, filterType: any): void => {
    let MR_part = filters[filterType].filter((filter: { detectorType: any; name: string; count: number; }) => filter.detectorType.indexOf("MR") !== -1);
    MR_part = MR_part.sort((a: { detectorType: any; name: string; count: number; },
                            b: { detectorType: any; name: string; count: number; }) => {return a.detectorType.localeCompare(b.detectorType);})
    let LR_part = filters[filterType].filter((filter: { detectorType: any; name: string; count: number; }) => filter.detectorType.indexOf("LR") !== -1);
    LR_part = LR_part.sort((a: { detectorType: any; name: string; count: number; },
                            b: { detectorType: any; name: string; count: number; }) => {return a.detectorType.localeCompare(b.detectorType);})
    let HC_part = filters[filterType].filter((filter: { detectorType: any; name: string; count: number; }) => filter.detectorType.indexOf("HC") !== -1);
    HC_part = HC_part.sort((a: { detectorType: any; name: string; count: number; },
                            b: { detectorType: any; name: string; count: number; }) => {return a.detectorType.localeCompare(b.detectorType);})
    filters[filterType] = MR_part.concat(LR_part).concat(HC_part);
  }

  const fillWithTheRestOfTheFilters = (filters: any, filterType: any): void => {
    if (filters[filterType] === undefined) {
      filters[filterType] = [];
    }
    const allDetectorsOfType = getAllDetectorsByType(t, filterType);
    for (let i = 0; i < allDetectorsOfType.length; ++i) {
      const alreadyExistsDetector = filters[filterType].find((filter: { detectorType: any; name: string; count: number; }) => filter.detectorType === allDetectorsOfType[i].detectorType);
      if (alreadyExistsDetector === undefined) {
        filters[filterType].push({name: allDetectorsOfType[i].name, detectorType: allDetectorsOfType[i].detectorType, count: 0});
      }
    }
  }

  const getTabFilters = (): IFilterSet => {
    switch (value) {
      case 0:
        return myTemplatesFilters;
      case 1:
        return receivedTemplatesFilters;
      default:
        return {};
    }
  };

  const getSelectedTabFilters = (): string[] => {
    switch (value) {
      case 0:
        return selectedMyTemplates;
      case 1:
        return selectedReceivedTemplates;
      default:
        return [];
    }
  };

  const getSelectedFilters = (selectedNames: string[]) => {
    switch (value) {
      case 0:
        setSelectedMyTemplates(selectedNames);
        break;
      case 1:
        setSelectedReceivedTemplates(selectedNames);
        break;
    }
  };

  const handleCurrentTime = () => {
    const currentDate = moment().valueOf();
    if (authStore?.userHistory) {
      authStore.userHistory.lastReceivedTemplatesSeen = currentDate;
      authStore.updateUserHistory(authStore.userHistory);
    }
  };

  const handleCurrentTimeNiko = () => {
    const currentDate = moment().valueOf();
    if (authStore?.userHistory) {
      authStore.userHistory.lastMyTemplatesSeen = currentDate;
      authStore.updateUserHistory(authStore.userHistory);
    }
  };

  const getCurrentHeaderContent = () : string => {
    if (currentTab === 0) {
      return t("translation:Header_Templates.myTemplatesContent");
    }

    return t("translation:Header_Templates.receivedTemplatesContent");
  }

  return (
    <Grid container>
      <TemplateHeader content={getCurrentHeaderContent()} />
      <Grid container item className={classes.templatesWrapper}>
        <Grid item className={classes.filerContainer}>
          <Typography className={classes.title}>
            {t("translation:Templates_Mytemplates.filter")}
          </Typography>
          <FilterTemplates
            getSelectedTabFilters={getSelectedTabFilters()}
            selectedNames={getSelectedFilters}
            filtersSet={getTabFilters()}
          />
        </Grid>
        <Grid item className={classes.templatesTabsContainer}>
          <AppBar position="static" className={classes.appBar}>
            <Tabs
              className={classes.wraperTabs}
              value={value}
              onChange={handleTabs}
              aria-label="simple tabs example"
            >
              <Tab
                label={
                  <Badge
                    badgeContent={authStore && authStore.myNewTemplates}
                    className={classes.badge}
                  >
                    {t("translation:Templates_Mytemplates.tabTitle")}
                  </Badge>
                }
                {...tabProps(0)}
                className={`${classes.tabLabel} ${typoClasses.tabTitles}`}
                fullWidth={true}
                onClick={handleCurrentTimeNiko}
              />
              <Tab
                label={
                  <Badge
                    badgeContent={authStore && authStore.newReceivedTemplates}
                    className={classes.badge}
                  >
                    {t("translation:Templates_Receivedtemplates.tabTitle")}
                  </Badge>
                }
                {...tabProps(1)}
                className={`${classes.tabLabel} ${typoClasses.tabTitles}`}
                onClick={handleCurrentTime}
                fullWidth={true}
              />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <MyTemplates
              filtersData={getTabsData}
              selectedMyTemplates={selectedMyTemplates}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ReceivedTemplates
              filtersData={getTabsData}
              selectedReceivedTemplates={selectedReceivedTemplates}
            />
          </TabPanel>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Template;
