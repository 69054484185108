import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useIntl } from "react-intl";
import moment from "moment-timezone";
import {
  Checkbox,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Grid,
  Modal
} from "@material-ui/core";
import UncheckedboxSVG from "../../common/components/UncheckedBox";
import Actions from "./Components/Actions";
import {
  getProjectById,
  getAllProjects,
  removeProjects,
} from "../../data-services/Projects/ProjectsService";
import { IProject } from "../../models/Project";
import ProjectHeader from "./Components/ProjectHeader";
import CheckedBoldBox from "../../common/components/CheckedBoldBox";
import { AppRoute } from "../../common/constants/routes";
import { getLocalizedRoute } from "../../common/services/i18n/utils/routeLocalization";
import useStyles from "./ProjectsList.style";
import { exportProjectsPDF } from "../../common/components/Export/ExportPDF";
import { exportProjectsExcel } from "../../common/components/Export/ExportExcel";
import {useAuth} from "../../context/context";
import LoadingSpinner from "../../LoadingSpinner";

const ProjectsList: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation(["translation"]);
  const { formatMessage, locale } = useIntl();
  const cellAlignment = "left";
  const [selectedProjects, setSelectedProjects] = React.useState<string[]>([]);
  const [projects, setProjects] = useState<IProject[]>([]);
  const [projectNames, setProjectNames] = useState<string[]>([]);
  const [singleProject, setSingleProject] = useState<IProject>();
  const timeZone = moment.tz.guess();
  const authStore = useAuth();
  const [sortedBy, setSortedBy] = useState<string>('name');
  const [allSelected, setAllSelected] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [dataLeftToFetch, setDataLeftToFetch] = useState(true);
  const [offset, setOffset] = useState(0);
  const [allDataFetched, setAllDataFetched] = useState(false);

  const pageLimit = 100;

  const redirectToProject = (
    event: React.MouseEvent<HTMLTableHeaderCellElement, MouseEvent>,
    project: IProject
  ) => {
    setSingleProject(projects.find((item) => project.id === item.id));
    window.open(
      getLocalizedRoute(AppRoute.Project, locale, formatMessage).replace(
        ":id",
        project.id
      ),
      "_blank"
    );
  };

  const fetchProject = async (projectId: string) => {
    if (projects[projects.map(e => e.id).indexOf(projectId)].sensorSettings.length > 0){
      return;
    }

    const { data } = await getProjectById(projectId, authStore?.getImpersonatedUserEmail() || null);
    //setProjectsToExport(projectsToExport.concat(data));
    let affectedProjectIndex = projects.map(e => e.id).indexOf(data.id);

    // Keep the counts
    data.daliCount = projects[affectedProjectIndex].daliCount;
    data.two30Count = projects[affectedProjectIndex].two30Count;

    projects.splice(projects.map(e => e.id).indexOf(data.id), 1, data)
    setProjects(projects);
  }

  const fetchData = async (withFullInfo: boolean = false, sortBy: string = 'name', offset: number = 0, limit: number = 100) => {
    if (!dataLeftToFetch && !withFullInfo) return;

    if (allSelected && withFullInfo) {
      return;
    }

    if (withFullInfo) {
      setAllSelected(true);

      setIsFetching(true);
      setDataLeftToFetch(false);
      offset = 0;
      limit = 9999;
      sortBy = 'none';
    }

    const { data } = await getAllProjects(authStore?.getImpersonatedUserEmail() || null, withFullInfo, sortBy, offset, limit);
    if (data) {
      let newData:any = [];
      if (offset > 0) {
        newData = [...projects, ...data];
        setProjects(newData);
      }
      else {
        newData = data;
        if (withFullInfo) {
          switch (sortBy) {
            case "name":
              setProjects(sortedProjectsByName(newData));
              break;
            case "date":
              setProjects(sortedProjectsByDate(newData));
              break;
            default:
              setProjects(newData);
          }
        }
        else{
          setProjects(newData);
        }
      }
      // sortProjects(sortedBy, data);
      setIsFetching(false);

      if (withFullInfo) {
        setAllDataFetched(true);
        setSelectedProjects(newData.map((p: IProject) => p.id));
      }

      if (data.length < pageLimit) {
        setDataLeftToFetch(false);
      }
    }
  };

  const handleScroll = (e: any) => {
    if (e.target.documentElement.scrollTop + window.innerHeight + 1 >= e.target.documentElement.scrollHeight)
    {
      setIsFetching(true);
      //fetchData(false, sortedBy, offset, pageLimit);
    }
  };

  useEffect(() => {
    fetchData(false, sortedBy, 0, pageLimit);
    window.addEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (!dataLeftToFetch) {
      if (allDataFetched) {
        setIsFetching(false);
        return;
      }
      if (allSelected) return;
      setIsFetching(false);
      //return;
    }
    if (!isFetching){
      return;
    }
    if(projects.length > 0 ){
        setOffset(offset+pageLimit)
        fetchData(false, sortedBy, offset+pageLimit, pageLimit);
    }
  }, [isFetching]);

  // useEffect(() => {
  //   if (!dataLeftToFetch) {
  //     setIsFetching(false);
  //   }
  // }, [dataLeftToFetch]);


  const sortedProjectsByName = (projectsToSort: IProject[]) => {
    return projectsToSort.sort((a, b) => {
      if (a.name.toUpperCase() < b.name.toUpperCase()) {
        return -1;
      }
      if (a.name.toUpperCase() > b.name.toUpperCase()) {
        return 1;
      }

      return 0;
    });
  };

  const sortedProjectsByDate = (projectsToSort: IProject[]) => {
    return projectsToSort.sort((a, b) => {
      if (moment(b.createdAt).isAfter(a.createdAt)) {
        return 1;
      }
      return -1;
    });
  };

  const sortProjects = (key: string, myProjects: IProject[] = []) => {
    let projectsToSort = [...projects];
    if (myProjects.length > 0) {
      projectsToSort = myProjects;
    }
    setSortedBy(key);

    if (dataLeftToFetch) {
      fetchData(false, key, 0, pageLimit);
      setOffset(0);
    }
    else {
      switch (key) {
        case "name":
          setProjects(sortedProjectsByName(projectsToSort));
          break;
        case "date":
          setProjects(sortedProjectsByDate(projectsToSort));
          break;
        default:
          setProjects(projectsToSort);
      }
    }
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    project: any
  ) => {
    if (event.target.checked) {
      fetchProject(project.id);

      setSelectedProjects(selectedProjects.concat(project.id));
      setProjectNames(projectNames.concat(project.name));
    } else {
      setSelectedProjects(selectedProjects.filter((id) => id !== project.id));
      setProjectNames(projectNames.filter((name) => name !== project.name));
    }
  };

  const deleteProjects = async (projectIds: string[]): Promise<any> => {
    try {
      const { status } = await removeProjects(projectIds, authStore?.getImpersonatedUserEmail() || null);
      if (status === "success") {
        fetchData(false, sortedBy, 0, 100);
        setOffset(0);
      }
    } catch (error) {
      return "error";
    }
  };

  const allProjectIds = projects.map((project: IProject) => project.id);

  const handleExportProjectsPDF = () => {
    try {
      const selectedProjectsExport = projects.filter((project) =>
        selectedProjects.includes(project.id)
      );
      const contactDetails = t("translation:SensorData.export.contactDetails")
                              + ' ' + authStore?.userHistory?.firstName + ', '
                              + authStore?.userHistory?.lastName + ', '
                              + authStore?.userHistory?.email;
      exportProjectsPDF(selectedProjectsExport, contactDetails);
    } catch (e) {
      console.log(e);
    }
  };

  const handleExportProjectsExcel = () => {
    try {
      const selectedProjectsExport = projects.filter((project) =>
      selectedProjects.includes(project.id)
      );
      exportProjectsExcel(selectedProjectsExport);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Grid container direction="column" className={classes.projectsWrapper}>
    <Modal open={isFetching}>
      <div style={{ top: '50%'}}>
        {
            isFetching
            &&
            <LoadingSpinner />
        }
        </div>
    </Modal>
      <ProjectHeader />
      <Grid item>
        <TableContainer className={classes.projectsContainer}>
          <Actions
            sortProjects={sortProjects}
            project={singleProject}
            projects={projects}
            deleteProjects={deleteProjects}
            selectedProjects={selectedProjects}
            setSelectedProjects={setSelectedProjects}
            projectNames={projectNames}
            allProjectIds={allProjectIds}
            exportProjectsExcel={handleExportProjectsExcel}
            exportProjectsPDF={handleExportProjectsPDF}
            fetchData={() => fetchData(true)}
          />
          <Table className={classes.table} aria-label="simple table">
            <colgroup>
              <col style={{ width: '5%' }} />
              <col style={{ width: '30%' }} />
              <col style={{ width: '25%' }} />
              <col style={{ width: '20%' }} />
              <col style={{ width: '15%' }} />
            </colgroup>
            <TableHead className={classes.projectsTableHead}>
              <TableRow>
                <TableCell className={classes.columnName}></TableCell>
                <TableCell className={classes.columnName}>
                  {t("translation:Projects_Myprojects.tableHeadings.name")}
                </TableCell>
                <TableCell className={classes.columnName} align={cellAlignment}>
                  {t("translation:Projects_Myprojects.tableHeadings.DALI-2")}
                </TableCell>
                <TableCell className={classes.columnName} align={cellAlignment}>
                  {t("translation:Projects_Myprojects.tableHeadings.230V")}
                </TableCell>
                <TableCell className={classes.columnName} align={"right"}>
                  {t(
                    "translation:Projects_Myprojects.tableHeadings.CreationDate"
                  )}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>

              {projects.map((project: IProject) => (
                <TableRow key={project.id} className={classes.rowContent}>
                  <TableCell align={cellAlignment} className={classes.check}>
                    <Checkbox
                      checked={selectedProjects.some((id) => id === project.id)}
                      onChange={(event) => handleChange(event, project)}
                      checkedIcon={<CheckedBoldBox />}
                      inputProps={{ "aria-label": "checkbox" }}
                      icon={<UncheckedboxSVG />}
                      disableRipple
                    />
                  </TableCell>
                  <TableCell
                    className={classes.projectName}
                    onClick={(event) => redirectToProject(event, project)}
                  >
                    {project.name ? (
                      `${project.name}`
                    ) : (
                      <img src="/img/dots.svg" alt="projectName" />
                    )}
                  </TableCell>
                  <TableCell
                    className={classes.content}
                    onClick={(event) => redirectToProject(event, project)}
                    align={cellAlignment}
                  >
                    {
                      project.daliCount
                    }
                  </TableCell>
                  <TableCell
                    className={classes.content}
                    align={cellAlignment}
                    onClick={(event) => redirectToProject(event, project)}
                  >
                    {
                      project.two30Count
                    }
                  </TableCell>
                  <TableCell
                    className={classes.content}
                    align={"right"}
                    onClick={(event) => redirectToProject(event, project)}
                  >
                    {moment
                      .tz(project.createdAt, timeZone)
                      .format("DD.MM.YYYY")}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default ProjectsList;
