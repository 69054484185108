import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useIntl } from "react-intl";
import {
  Box,
  Button,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from "@material-ui/core";
import validators from "../../../common/utils/validators";
import { getLocalizedRoute } from "../../../common/services/i18n/utils/routeLocalization";
import { AppRoute } from "../../../common/constants/routes";
import useButtonStyles from "../../../common/styles/buttonStyles";
import useStyles from "./ForgotPassword.style";
import { IGetResetEmailModel } from "../../../models/AuthModel";
import { sendResetPasswordLink } from "../../../data-services/Auth/AuthService";
import i18n from "../../../i18n";

const ForgotPassword: React.FC = () => {
  const classes = useStyles();
  const buttonClasses = useButtonStyles();
  const { t } = useTranslation(["translation"]);
  const [email, setEmail] = useState<string>();
  const [isEmailValid, setIsEmailValid] = useState(false);
  const { formatMessage, locale } = useIntl();
  let history = useHistory();

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = event.target.value;
    if (newEmail !== undefined) {
      setEmail(newEmail);
      setIsEmailValid(validators.isEmailValid(newEmail));
    }
  };

  const redirect = () => {
    history.push(
      getLocalizedRoute(AppRoute.ResetEmailConfirmation, locale, formatMessage)
    );
  };

  const sendResetPassword = async (email: string) => {
    try {
      if (email) {
        const userData: IGetResetEmailModel = { email, browserLanguage: i18n.language };
        await sendResetPasswordLink(userData);

        redirect();
      }
    } catch (error) {
      return { status: "error", message: "Error" };
    }
  };

  const handleSendResetPassword = () => {
    email && sendResetPassword(email);
  };

  return (
    <Grid container className={classes.forgotPasswordWrapper}>
      <Grid item className={classes.itemContainer}>
        <Typography className={classes.title}>
          {t("translation:login_Forgotpassword.content")}
        </Typography>
        <img
          className={classes.image}
          src="/img/Detectortoolportal_height_small_cut.jpg"
          alt="forgotPassword"
        />
      </Grid>
      <Grid item className={classes.contentWrapper}>
        <Typography component="h1" className={classes.formTitle}>
          {t("translation:login_Forgotpassword.title")}
        </Typography>
        <div
          className={`${classes.verticalInputSpacing} ${(!!email || email === "") && !isEmailValid
            ? classes.errorBorder
            : ""
            } `}
        >
          <InputLabel className={classes.inputLabel} required={true}>
            {t("translation:register.email")}
          </InputLabel>
          <TextField
            type="email"
            variant="outlined"
            focused={false}
            placeholder="name@gmail.com"
            className={classes.textInput}
            onChange={handleEmailChange}
          />
          {(!!email || email === "") && !isEmailValid ? (
            <Box>
              <Typography className={classes.error}>
                {email === ""
                  ? `${t("translation:common.requiredError")}`
                  : `${t("translation:common.emailError")}`}
              </Typography>
            </Box>
          ) : (
            ""
          )}
          <InputLabel className={classes.inputAsterixOnLeftLabel} required={true}>
            {t("translation:common.requiredField")}
          </InputLabel>
        </div>

        <Button
          onClick={handleSendResetPassword}
          className={`${buttonClasses.primaryButton} ${classes.button}`}
        >
          {t("translation:login_Forgotpassword.resetButton")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default ForgotPassword;
