import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Typography, Popover, Button, Modal } from "@material-ui/core";
import { useIntl } from "react-intl";
import { useAuth } from "../../../context/context";
import { AppRoute } from "../../../common/constants/routes";
import { getLocalizedRoute } from "../../../common/services/i18n/utils/routeLocalization";
import { useTranslation } from "react-i18next";
import useStyles from "./MyAccount.style";
import { cognitoWhoAmI } from "../../../data-services/Auth/AuthService";
import i18n from "../../../i18n";
import { languageList } from "../../../common/constants/countries";
import { hasThisCountryWithThisLanguageSpecialLocaleCode } from "../../../common/enums/laguages";
import { CIAM_CALLBACK_ROUTE_URL } from "../../../common/constants/routeSuffixes";
import LoadingSpinner from "../../../LoadingSpinner";

interface IMyAccount {
  hideInstallerBar?(): void;
}

const MyAccount: React.FC<IMyAccount> = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  //const [retryCognitoLogin, setRetryCognitoLogin] = useState<boolean>(false);
  //const [idToken, setIdToken] = useState<string | null>(null);
  const { formatMessage, locale } = useIntl();
  const [isFetching, setIsFetching] = useState(true);
  const authStore = useAuth();
  const history = useHistory();
  const { t } = useTranslation(["translation"]);

  const { hideInstallerBar } = props;

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  let query = useQuery();
  const id_token = query.get('id_token');

  const logoutInitiated = localStorage.getItem('logoutInitiated')

  const postCognitoLogin = async (retry: boolean = false, retries: number = 0) => {
    const loginInitiated = localStorage.getItem('loginInitiated')

    if (!authStore?.isLoggedIn && loginInitiated) {
      //Replace token with OIDC token; set the token for calling whoAmI
      authStore?.setAuthTokens(id_token);
      //
      let localeCodeToSet = locale;
      if (authStore) {
        const { data: user, status } = await cognitoWhoAmI();
        if (status === "success") {
          localStorage.removeItem("loginInitiated");
          authStore?.setIsLogged(true);

          authStore.persistUser(user);
          authStore.countReceivedTemplates();

          const language = languageList.find((l) => l.languageId === user.language);
          const country = user.country;
          if (language?.localeCode) {
            localeCodeToSet = hasThisCountryWithThisLanguageSpecialLocaleCode(country, language?.name);
            if (localeCodeToSet === undefined) {
              localeCodeToSet = language?.localeCode;
            }
            i18n.changeLanguage(localeCodeToSet);
            authStore.updateCurrentLanguageCode(localeCodeToSet);
          }
        }
        else {
          if (!retry) {
            //console.log('failed try 2');
            postCognitoLogin(true, 2);
          }
          else {
            authStore.setAuthTokens();
            authStore.removeItems();
            authStore.setIsLogged(false);
          }
          // if (retries > 1) {
          //   console.log('failed try again 1');
          //   postCognitoLogin(true, retries-1);
          // }
        }
        history.push(getLocalizedRoute(AppRoute.Home, localeCodeToSet, formatMessage));
      }
    }
    setIsFetching(false);
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (authStore?.isLoggedIn) {
      setAnchorEl(event.currentTarget);
    } else {
      localStorage.setItem('loginInitiated', 'true');
      // let redirectUrl = window.location.origin + getLocalizedRoute(AppRoute.Home, locale, formatMessage);
      //let redirectUrl = window.location.origin + '/api/account/ciamcallback';
      let redirectUrl = process.env.REACT_APP_BASE_URL + CIAM_CALLBACK_ROUTE_URL;

      window.location.replace(process.env.REACT_APP_CIAM_PROVIDER_URL+'/login?redirect_uri='+redirectUrl);
      //history.push(getLocalizedRoute(AppRoute.Home, locale, formatMessage));
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const resetInstallerView = () => {
    authStore?.setImpersonatedUserHistory(null);
    authStore?.setImpersonatedUserEmail(null);

    localStorage.setItem("toImpersonateUserEmail", "null");

    if (hideInstallerBar) {
      hideInstallerBar();
    }
  }

  const logout = () => {
    authStore?.setAuthTokens();
    authStore?.removeItems();
    //authStore?.setIsLogged(false);
    authStore?.setIsSuperUser(false);

    resetInstallerView();

    handleClose();
    let redirectUrl = window.location.origin + getLocalizedRoute(AppRoute.Home, locale, formatMessage);
    //await cognitoLogout();
    window.location.replace(process.env.REACT_APP_CIAM_PROVIDER_URL+'/logout?redirect_uri='+redirectUrl);
    //history.push(getLocalizedRoute(AppRoute.Home, locale, formatMessage));
  };

  const redirectToMyAccount = () => {
    history.push(getLocalizedRoute(AppRoute.MyAccount, locale, formatMessage));
    handleClose();
  };

  useEffect(() => {
    if (logoutInitiated === '2') {
      localStorage.removeItem('logoutInitiated');
      logout();
    }
    else {
      postCognitoLogin();
    }
  }, []);

  // useEffect(() => {
  //   postCognitoLogin(2);
  // }, [retryCognitoLogin]);
  useEffect(() => {
    if (!isFetching){
      return;
    }
  }, [isFetching]);

  return (
    <>
    <Modal open={isFetching}>
      <div style={{ top: '50%'}}>
        {
          isFetching
          &&
          <LoadingSpinner />
        }
      </div>
    </Modal>
      <Button
          aria-describedby={id}
          onClick={handleClick}
          className={classes.userName}
      >
        <img
            src={`/img/${
                authStore?.isLoggedIn ? "LogedInIcon" : "ProfileSVG"
            }.svg`}
            alt="person"
        />
        <Typography component="span">
          {authStore?.isLoggedIn
              ? authStore.userHistory?.name
              : t("translation:common.loginLabel")}
        </Typography>
      </Button>
      <Popover
        id={id}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: -16,
          horizontal: "center",
        }}
        className={classes.dropdown}
      >
        <Typography
          onClick={redirectToMyAccount}
          className={classes.dropdownItem}
        >
          {t("translation:menu.myAccount")}
        </Typography>
        <Typography onClick={logout} className={classes.dropdownItem}>
          {t("translation:menu.logout")}
        </Typography>
      </Popover>
    </>
  );
};

export default MyAccount;
