import { makeStyles, createStyles } from "@material-ui/core/styles";
import colors from "../../common/styles/colors";
import { commonParagraph14px } from "../../common/styles/typography";

const useStyles = makeStyles((theme) =>
  createStyles({
    templatesWrapper: {
      flexWrap: "nowrap",
      paddingBottom: "50px",
    },
    appBar: {
      backgroundColor: colors.whiteColor,
      boxShadow: "none",
    },
    templatesTabsContainer: {
      width: "75%",
      paddingTop: theme.spacing(4),
      paddingRight: "120px",
      "& .MuiBox-root": {
        padding: "0",
      },
    },
    wraperTabs: {
      borderBottom: `1px solid ${colors.pinkSwan}`,
      "& div > span:last-child": {
        backgroundColor: colors.primaryColor,
        height: "8px",
        with: "50%",
      },
      "& .MuiTabs-flexContainer": {
        justifyContent: "space-around",
      },
    },
    tabLabel: {
      textTransform: "none",
      width: "50%",
      maxWidth: "100%",
      paddingBottom: "17px",
      paddingTop: "20px",
      color: colors.grey,
      "&.MuiTab-textColorInherit.Mui-selected": {
        color: colors.primaryColor,
      },
    },
    badge: {
      "& .MuiBadge-badge": {
        height: "16px",
        minWidth: "16px",
        fontSize: "12px",
        lineHeight: "16px",
        fontFamily: "Roboto, sans-serif",
        color: colors.primaryColor,
        backgroundColor: colors.veryLightGreyColor,
      },
      "& .MuiBadge-anchorOriginTopRightRectangle": {
        top: "-5px",
      },
    },
    filerContainer: {
      backgroundColor: colors.secondaryColor,
      width: "20%",
      minWidth: "300px",
      margin: "32px 90px 0 16px",
      padding: "32px 24px 28px",
    },
    title: {
      ...commonParagraph14px,
      color: colors.blackColor,
      fontWeight: 700,
    },
  })
);

export default useStyles;
